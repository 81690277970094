import React, { useState, useEffect, useCallback } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  Button
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import SettingsNotification from 'src/components/SettingsNotification';
import InternetConnection from 'src/components/InternetConnection';
import { FeeSetupProvider } from 'src/contexts/ImportSetupContext';
import { ImportCompanySetupProvider } from 'src/contexts/ImportForCompanyContext';
import { ModalExecutionContextProvider } from 'src/contexts/ModalExecution';

import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import gql from 'graphql-tag';
import client from 'src/utils/GQLAdmissionClient';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import { StreamProvider } from 'src/contexts/StreamContext';
import { YearProvider } from 'src/contexts/YearContext';
import { TermProvider } from 'src/contexts/TermContext';
import { DashboardProvider } from 'src/contexts/DashboardContext';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

console.log(navigator.onLine);
const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { settings } = useSettings();
  const isMountedRef = useIsMountedRef();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const refresh = () => {
    window.location.reload(false);
  };

  window.addEventListener('offline', function(e) {
    enqueueSnackbar('Internet not Connected', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
        autoHideDuration: null
      },
      autoHideDuration: null,
      variant: 'error',
      action: <Button onClick={refresh}>Refresh</Button>
    });
    console.log('offline');
  });

  window.addEventListener('online', function(e) {
    console.log('online');
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense={false} maxSnack={3}>
            <Router history={history}>

              <AuthProvider>
              <DashboardProvider>
                <FeeSetupProvider>
                  <ModalExecutionContextProvider>
                  <ImportCompanySetupProvider>
                    <StreamProvider>
                      <TermProvider>
                        <YearProvider>
                          <GlobalStyles />
                          <ScrollReset />
                          <GoogleAnalytics />
                          {/* <CookiesNotification />
                             <SettingsNotification /> */}
                          <InternetConnection />
                          
                          {renderRoutes(routes)}
                        </YearProvider>
                      </TermProvider>
                    </StreamProvider>
                  </ImportCompanySetupProvider>
                  </ModalExecutionContextProvider>
                </FeeSetupProvider>
              </DashboardProvider>
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
