import React, { createContext, useReducer } from 'react';

import { useSnackbar } from 'notistack';
import axios from 'axios';

const initialCreateState = {
  isCreated: false,
  isInitialised: false,
  user: null,
  users: null,
  status:null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isCreated, user } = action.payload;

      return {
        ...state,
        isCreated,
        isInitialised: true,
        user
      };
    }

    case 'UPDATE_INSTITUTION': {
      const { user ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        user
      };
    }

    case 'UPDATE_DATA': {
      const { data ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        data
      };
    }
    
    case 'UPDATE_RESPONSE': {
      const { message ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull,
        message
      };
    }

    case 'FACE_ATTENDANCE': {
      const { status  } = action.payload;

      return {
        ...state,
      
        status
      };
    }
    case 'UPDATE_INSTITUTION_ERR': {
    const { err ,updatesuccessfull } = action.payload;

      return {
        ...state,
        updatesuccessfull: false,
        err
      };
    }  
    case 'ADD_IMPORT': {
      const { user1 ,updatesuccessfull1 } = action.payload;
    
      return {
        ...state,
        updatesuccessfull1,
        user1
      };
    }
    case 'ADD_IMPORT_ERROR': {
      const { err1 ,updatesuccessfull1 } = action.payload;
    
      return {
        ...state,
        updatesuccessfull1: false,
        err1
      };
    }
    default: {
      return { ...state };
    }
  }
};

const ImportForCompanySetupContext = createContext({
  ...initialCreateState,
  addprogram: () => Promise.resolve(),
  addprogram1 : () => Promise.resolve(),
  updatestudentemail: () => Promise.resolve(),
  addmarks1 : () => Promise.resolve(),
  addassignment : () => Promise.resolve(),
  addattendance : () => Promise.resolve(),
  addlabIAmarks : () => Promise.resolve(),
  addactivity : () => Promise.resolve(),
  faceAttendance : () => Promise.resolve(),
  promoteStudentsFinal : () => Promise.resolve(),
  promoteStudents : () => Promise.resolve(),
  importFormLayoutData : () => Promise.resolve(),
});

export const ImportCompanySetupProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCreateState);
  const { enqueueSnackbar } = useSnackbar();

  const addprogram = async (data  
     ) => {
         
   
    try {
      let response;
      if (process.env.REACT_APP_ENV === 'test') {
      response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_ia_attendance", data );
      }else{
        response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_ia_attendance", data );
      }
      
      
      dispatch({
        type: 'UPDATE_INSTITUTION',
        payload: {
          user : response.data,
          updatesuccessfull : true
        }
      });
    } catch (err) {
      
      if (err.response) {
      dispatch({
        type: 'UPDATE_INSTITUTION_ERR',
        payload: {
          err: err.response.data,
          updatesuccessfull : false
        }
      });
    }
      
    }
  };

  const faceAttendance = async (data  
    ) => {
        
  
   try {
     let response;
     response = await  axios.post("https://gl3d5qeyfjj5qougp5c7vfwmwu0qnnqk.lambda-url.ap-south-1.on.aws/", data );
     
     
     
     if(response.data.status=='fail')
            await enqueueSnackbar('Please try again', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              variant: 'error'
            });
            if(response.data.status=='success')
            await enqueueSnackbar('User verified', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              variant: 'success'
            });
     dispatch({
       type: 'FACE_ATTENDANCE',
       payload: {
         status : response.data.status,
       }
     });
   } catch (err) {
     
  //    if (err.response) {
  //    dispatch({
  //      type: 'UPDATE_INSTITUTION_ERR',
  //      payload: {
  //        err: err.response.data,
  //        updatesuccessfull : false
  //      }
  //    });
  //  }
     
   }
 };

 const promoteStudents = async (data  
  ) => {
      

 try {
   let response;
   response = await  axios.post("https://usgx22bxcxy7iemolnen6zhkie0tjfdi.lambda-url.ap-south-1.on.aws/", data );
   
   
   
 
   dispatch({
     type: 'UPDATE_DATA',
     payload: {
      data : response.data,
     }
   });
 } catch (err) {
   
   if (err.response) {
    dispatch({
      type: 'UPDATE_INSTITUTION_ERR',
      payload: {
        err: err.response.data,
        updatesuccessfull : false
      }
    });
  }
   
 }
};

const promoteStudentsFinal = async (data  
  ) => {
      

 try {
   let response;
   response = await  axios.post("https://ocy2wuwksx5v4farxop6mskv4e0sztxh.lambda-url.ap-south-1.on.aws/", data );
   
   
   
 
   dispatch({
     type: 'UPDATE_RESPONSE',
     payload: {
      message : response.data.message,
     }
   });
 } catch (err) {
   
   if (err.response) {
    dispatch({
      type: 'UPDATE_INSTITUTION_ERR',
      payload: {
        err: err.response.data,
        updatesuccessfull : false
      }
    });
  }
   
 }
};


  const addprogram1 = async (data  
    ) => {
        
   try {
     let response;
     if (process.env.REACT_APP_ENV === 'test') {
     response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/upload_ia_attendance", data );
     }else{
      response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/upload_ia_attendance", data );
     }
     
     dispatch({
       type: 'ADD_IMPORT',
       payload: {
         user1 : response.data,
         updatesuccessfull1 : true
       }
     });
   } catch (err) {
     
     if (err.response) {
     dispatch({
       type: 'ADD_IMPORT_ERROR',
       payload: {
         err1: err.response.data,
         updatesuccessfull1 : false
       }
     });
   }
     
   }
 };

 const updatestudentemail = async (data  
  ) => {
      

 try {
   let response;
   if (process.env.REACT_APP_ENV === 'test') {
   response = await  axios.post("https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/test/import_student_email", data );
   }else{
    response = await  axios.post("https://3yj8easi7j.execute-api.ap-south-1.amazonaws.com/prod/import_student_email", data );
   }
   
   dispatch({
     type: 'UPDATE_INSTITUTION',
     payload: {
       user : response.data,
       updatesuccessfull : true
     }
   });
 } catch (err) {
   
   if (err.response) {
   dispatch({
     type: 'UPDATE_INSTITUTION_ERR',
     payload: {
       err: err.response.data,
       updatesuccessfull : false
     }
   });
 }
   
 }
};


const addmarks1 = async (data  
 ) => {
     
try {
  let response;
  if (process.env.REACT_APP_ENV === 'test') {
  response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_ia_attendance", data );
  }else{
    response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_ia_attendance", data );
  }
  
  dispatch({
    type: 'ADD_IMPORT',
    payload: {
      user1 : response.data,
      updatesuccessfull1 : true
    }
  });
} catch (err) {
  
  if (err.response) {
  dispatch({
    type: 'ADD_IMPORT_ERROR',
    payload: {
      err1: err.response.data,
      updatesuccessfull1 : false
    }
  });
}
  
}
};

const addassignment = async (data  
  ) => {
      

 try {
   let response;
   if (process.env.REACT_APP_ENV === 'test') {
   response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_asmt_marks", data );
  }else{
    response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_asmt_marks", data );
  }
   
   dispatch({
     type: 'UPDATE_INSTITUTION',
     payload: {
       user : response.data,
       updatesuccessfull : true
     }
   });
 } catch (err) {
   
   if (err.response) {
   dispatch({
     type: 'UPDATE_INSTITUTION_ERR',
     payload: {
       err: err.response.data,
       updatesuccessfull : false
     }
   });
 }
   
 }
};

      const addattendance = async (data  
        ) => {
            

      try {
        let response;
        if (process.env.REACT_APP_ENV === 'test') {
        response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_stu_attendance", data );
        }else{
          response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_stu_attendance", data );
        }
        
        dispatch({
          type: 'UPDATE_INSTITUTION',
          payload: {
            user : response.data,
            updatesuccessfull : true
          }
        });
      } catch (err) {
        
        if (err.response) {
        dispatch({
          type: 'UPDATE_INSTITUTION_ERR',
          payload: {
            err: err.response.data,
            updatesuccessfull : false
          }
        });
      }
        
      }
      };

      const addlabIAmarks = async (data  
        ) => {
            
      
       try {
         let response;
         if (process.env.REACT_APP_ENV === 'test') {
         response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_lab_ia_marks", data );
         }else{
          response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_lab_ia_marks", data );
         }
         
         dispatch({
           type: 'UPDATE_INSTITUTION',
           payload: {
             user : response.data,
             updatesuccessfull : true
           }
         });
       } catch (err) {
         
         if (err.response) {
         dispatch({
           type: 'UPDATE_INSTITUTION_ERR',
           payload: {
             err: err.response.data,
             updatesuccessfull : false
           }
         });
       }
         
       }
      };

      const addactivity = async (data  
        ) => {
            
      
       try {
         let response;
         if (process.env.REACT_APP_ENV === 'test') {
         response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/verify_activity_marks", data );
         }else{
          response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/verify_activity_marks", data );
         }
         
         dispatch({
           type: 'UPDATE_INSTITUTION',
           payload: {
             user : response.data,
             updatesuccessfull : true
           }
         });
       } catch (err) {
         
         if (err.response) {
         dispatch({
           type: 'UPDATE_INSTITUTION_ERR',
           payload: {
             err: err.response.data,
             updatesuccessfull : false
           }
         });
       }
         
       }
      };

      const importFormLayoutData = async (data  
      ) => {
          
    
     try {
       let response;
       if (process.env.REACT_APP_ENV === 'test') {
       response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/test/import_form_layout_data", data );
       }else{
        response = await  axios.post("https://fprqyudop4.execute-api.ap-south-1.amazonaws.com/prod/import_form_layout_data", data );
       }
       console.log(response)
       dispatch({
         type: 'UPDATE_INSTITUTION',
         payload: {
           user : response.data,
           updatesuccessfull : true
         }
       });
     } catch (err) {
       
       if (err.response) {
       dispatch({
         type: 'UPDATE_INSTITUTION_ERR',
         payload: {
           err: err.response.data,
           updatesuccessfull : false
         }
       });
     }
       
     }
    };


  return (
    <ImportForCompanySetupContext.Provider
      value={{
        ...state,
        addprogram,
        faceAttendance,
        promoteStudents,
        promoteStudentsFinal,
        addprogram1,
        updatestudentemail,
        addmarks1,
        addassignment,
        addattendance,
        addlabIAmarks,
        addactivity,
        importFormLayoutData
      }}
    >
      {children}
    </ImportForCompanySetupContext.Provider>
  );
};

export default ImportForCompanySetupContext;